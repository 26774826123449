@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;400&display=swap');
body
{
    /* change text color to white */
    color: white;
}

/* increase checkbox size */
input[type=checkbox]
{
    width: 20px;
    height: 20px;
    
}
html, body
{
    overflow: hidden;
}

h1
{
    font-family: 'Press Start 2P', cursive;
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

hr
{
    border: 5px solid rgb(32, 69, 110);
}

h2
{
    text-align: center; 
    
    color: white;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    /* underline text */
    text-decoration: underline;
}
footer
{
    
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-top: px;
    margin-bottom: 10px;
}
.webgl
{
    outline: none;
    border: 5px solid rgb(32, 69, 110);
    border-radius: 10px; 
    
}

.card.bg-secondary.pb-3
{
    border: 5px solid rgb(32, 69, 110);
    border-radius: 10px;
}

.container.card.bg-secondary
{
    border: 5px solid rgb(32, 69, 110);
    border-radius: 10px;
}


.sliderText
{
    justify-content: center;
    margin-left: 0%;
}

.slider
{
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: auto;
    margin-left: auto; */
    
}
label
{
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    color: rgb(255, 255, 255);
    
}

#generateBtn
{
    border-radius: 10px;
    border: 2px solid rgb(32, 69, 110);
    background: rgb(32, 69, 110);
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    cursor: pointer;
    
    
}
#generateBtn:hover
{
    background: rgb(0, 0, 0);
    color: rgb(32, 69, 110);
}
#generateBtn:active
{
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
}
#solveBtn
{
    
    
    border-radius: 10px;
    border: 2px solid rgb(32, 69, 110);
    background: rgb(32, 69, 110);
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    cursor: pointer;
}
#solveBtn:hover
{
    background: rgb(0, 0, 0);
    color: rgb(32, 69, 110);
}
#solveBtn:active
{
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
}
#resetBtn
{
    display: none;
    
    border-radius: 10px;
    border: 2px solid rgb(32, 69, 110);
    background: rgb(32, 69, 110);
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    cursor: pointer;
}
#clearBtn
{
    border-radius: 10px;
    border: 2px solid rgb(32, 69, 110);
    background: rgb(32, 69, 110);
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    cursor: pointer;
}
#clearBtn:hover
{
    background: rgb(0, 0, 0);
    color: rgb(32, 69, 110);
}
#clearBtn:active
{
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
}
#resetBtn:hover
{
    background: rgb(0, 0, 0);
    color: rgb(32, 69, 110);
}
#resetBtn:active
{
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
}

#playPauseBtn
{
    display: none;
    
    border-radius: 10px;
    border: 2px solid rgb(32, 69, 110);
    background: rgb(32, 69, 110);
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    cursor: pointer;
}
#playPauseBtn:hover
{
    background: rgb(0, 0, 0);
    color: rgb(32, 69, 110);
}
#playPauseBtn:active
{
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
}


#codeStats
{
    color: rgb(255, 255, 255);
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 20px;
}




/* pre.code:before {
    counter-reset: listing;
}
pre.code code {
    counter-increment: listing;
}
pre.code code:before {
    content: counter(listing) ". ";
    width: 8em;         
    padding-left: auto; 
    margin-left: auto;  
    text-align: left;  
} */

#function
{
    color: gold;
}

#variable
{
    color: gold
}

#if
{
    color: rgb(201, 20, 224);
}

#code
{
    color: rgb(16, 129, 163);
}

.lineNums
{
    color: rgb(129, 129, 129)
}


/* Ujarak */
.button--ujarak {
	-webkit-transition: border-color 0.4s, color 0.4s;
	transition: border-color 0.4s, color 0.4s;
    /* center */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    margin-top: 10px;
    color: rgb(255, 255, 255);
    background-color: rgb(32, 69, 110);
    font-family: 'Kanit', sans-serif;
    font-weight: 100;
}

.button--ujarak:hover {
	color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.171);
}

.button--ujarak:active {
	color: rgb(255, 255, 255);
    background-color: rgba(255, 0, 0, 0.171);
}

#text3d
{
    color: #ffffff;
    letter-spacing: .15em;
    text-shadow: 
      -1px -1px 1px #efede3, 
      0px 1px 0 #2e2e2e, 
      0px 2px 0 #2c2c2c, 
      0px 3px 0 #2a2a2a, 
      0px 4px 0 #282828, 
      0px 5px 0 #262626, 
      0px 6px 0 #242424, 
      0px 7px 0 #222, 
      0px 8px 0 #202020, 
      0px 9px 0 #1e1e1e, 
      0px 10px 0 #1c1c1c, 
      0px 11px 0 #1a1a1a, 
      0px 12px 0 #181818, 
      0px 13px 0 #161616, 
      0px 14px 0 #141414, 
      0px 15px 0 #121212,
      2px 20px 5px rgba(0, 0, 0, 0.9),
      5px 23px 5px rgba(0, 0, 0, 0.3),
      8px 27px 8px rgba(0, 0, 0, 0.5),
      8px 28px 35px rgba(0, 0, 0, 0.9);
}


#BFS{
    font-size: x-large;
}

#Dijkstra{
display: none;
font-size: x-large;
}
#Astar{
display: none;
font-size: x-large;
}


.weightCard
{
    height: 50px;
    width: 50px;
    border: 2px solid rgb(0, 0, 0);
    /* center text */
    display: flex;
    justify-content: center;
    align-items: center;
    /* text color */
    color: rgb(255, 255, 255);
}